
function randBetween(min, max) {
    return Math.floor(Math.random() * max) + min;
}

function createRem2px() {
    let docEl = document.documentElement;

    function rem2px(rem) {
        let d = docEl.clientWidth <= 480 ? docEl.clientWidth : 480;
        return Math.floor((rem * d) / 750);
    }

    return rem2px;
}

const rem2px = createRem2px()

function numberWithCommas(x, s) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, s);
}

function throttle(fn, delay) {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        if (!timer) {
            timer = setTimeout(function () {
                fn.apply(context, args);
                timer = null;
            }, delay);
        }
    }
}

function debounce(fn, delay) {
    let time = null;
    let flag = true
    return function () {
        if (!flag) return;
        fn.call(this);
        flag = false
        if (time !== null) {
            clearTimeout(time);
        }
        time = setTimeout(() => {
            flag = true
        }, delay)
    }
}

export {
    randBetween,
    rem2px,
    numberWithCommas,
    throttle,
    debounce
}