const appname = 'group'
// 真实环境
let test = 0
// 测试环境
// let test = 1
const baseURL = 'https://tg.tuyunworld.com/api'

export {
    appname,
    test,
    baseURL
}