import axios from '@/http/axios'
import { appname } from '@/config/global'

function login(data) {
    return axios({
        url: '/auth/login',
        method: 'post',
        data: { appname, ...data }
    })
}

function getUserInfo(){
    return axios({
        url: '/user/info',
        method: 'get'
    })
}

function getUser() {
    return axios({
        url: '/dotcoin/data/init',
        method: 'get'
    })
}

function getMenu(data) {
    return axios({
        url: '/getMenu',
        method: 'post',
        data
    })
}

function updatePoint(data) {
    return axios({
        url: '/dotcoin/data/post',
        method: 'post',
        data
    })
}

function upDot() {
    return axios({
        url: '/group/data/upgrade/dot/level',
        method: 'post'
    })
}

function upEnergy() {
    return axios({
        url: '/group/data/upgrade/energy/level',
        method: 'post'
    })
}

function getGroup(data) {
    return axios({
        url: '/group/data/group',
        method: 'get',
        data
    })
}

function getFriends(data) {
    return axios({
        url: '/group/data/friend',
        method: 'get',
        data
    })
}

function sendMessage(data) {
    return axios({
        url: '/tgbot/sendMessage',
        method: 'post',
        data
    })
}
function getTasks(data) {
    return axios({
        url: '/group/data/task',
        method: 'get',
        data
    })
}

function setTask(data) {
    return axios({
        url: `/group/data/optask/${data.id}`,
        method: 'get',
    })
}


function doublePoint(){
    return axios({
        url: '/group/data/doubling',
        method: 'post'
    })
}


export default {
    login,
    getUser,
    getMenu,
    updatePoint,
    upDot,
    upEnergy,
    getGroup,
    getFriends,
    getUserInfo,
    sendMessage,
    getTasks,
    setTask,
    doublePoint
}