import router from '@/router'
const tw = {}
tw.WebApp = window.Telegram.WebApp;

tw.init = function () {
    initSize()
    initBackBtn()
}

function initSize() {
    if (!tw.WebApp.isExpanded) tw.WebApp.expand();
}

console.log(tw.WebApp.BackButton)

function initBackBtn() {
    tw.WebApp.BackButton.isVisible = true
    addBackEvent()
}

function addBackEvent() {
    console.log('绑定返回事件', router)
    tw.WebApp.BackButton.onClick(() => {
        console.log('返回', router)
        router.go(-1)
    })
}

export default tw