import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=>import('../pages/home/index.vue')
  },
  {
    path: '/earn',
    name: 'earn',
    component: ()=>import('../pages/earn/index.vue')
  },
  {
    path: '/invite',
    name: 'invite',
    component: ()=>import('../pages/invite/index.vue')
  },
  {
    path: '/boot',
    name: 'boot',
    component: ()=>import('../pages/boot/index.vue')
  },
  {
    path: '/double',
    name: 'double',
    component: ()=>import('../pages/double/index.vue')
  },
  {
    path: '/market',
    name: 'market',
    component: ()=>import('../pages/market/index.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: ()=>import('../pages/order/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router