
function randBetween(min, max) {
    return Math.floor(Math.random() * max) + min;
}

function createRem2px() {
    let docEl = document.documentElement;

    function rem2px(rem) {
        let d = docEl.clientWidth <= 480 ? docEl.clientWidth : 480;
        return Math.floor((rem * d) / 750);
    }

    return rem2px;
}

const rem2px=createRem2px()

function numberWithCommas(x, s) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, s);
}

export {
    randBetween,
    createRem2px,
    rem2px,
    numberWithCommas
}