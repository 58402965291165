import axios from '@/http/axios'

function getMarketList(data) {
    return axios({
        url: '/market/p2p',
        method: 'post',
        data
    })
}

function transact(data) {
    return axios({
        url: '/market/transaction',
        method: 'post',
        data
    })
}

export default {
    getMarketList,
    transact
}