import { baseURL } from '@/config/global'

export default {
    method: 'get',
    baseURL,
    headers: {
        // 'Content-Type': 'application/json;charset=UTF-8'
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {},
    // widthCredentials: true,
    // responseType: 'json'
}