<template>
  <div class="page">
    <div class="main">
      <canvas id="bg" ref="bg"></canvas>
      <Load v-if="isLoading"></Load>
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { rem2px, randBetween } from "@/assets/js/tool";
import { userApi } from "@/http";
import Load from "@/components/ui/Load";
import '@/js/bg'

const bg = ref();
const store = useStore();
const isLoading = ref(true);

class Dots {
  constructor(count, canvasSize) {
    this.value = [];
    this.count = count;
    this.canvasSize = canvasSize;
    this.maxSpeed = 2;

    this.getDots();
  }

  getDots() {
    const { count, canvasSize, maxSpeed } = this;
    let dots = [];
    for (let i = 0; i < count; i++) {
      let dot = {
        x: randBetween(0, canvasSize.width),
        y: randBetween(0, canvasSize.height),
        r: randBetween(1, 3),
        dx: randBetween(-maxSpeed, maxSpeed),
        dy: randBetween(-maxSpeed, maxSpeed),
        color: "#967526",
      };
      dots.dx = dots.dx === 0 ? 1 : dots.dx;
      dots.dy = dots.dy === 0 ? 1 : dots.dy;
      dots.push(dot);
    }
    this.value = dots;
    return this.value;
  }

  updateDot(dot, canvasSize) {
    if (dot.x + dot.r > canvasSize.width + 40 || dot.x - dot.r < -40) {
      dot.dx = -dot.dx;
    }
    if (dot.y + dot.r > canvasSize.height + 40 || dot.y - dot.r < -40) {
      dot.dy = -dot.dy;
    }
    dot.x = dot.x + dot.dx;
    dot.y = dot.y + dot.dy;
  }
}

class CanvasAnimation {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = this.canvas.getContext("2d");
    this.animationId = 0;
    this.initSize();
    this.dots = new Dots(30, this.size);
    this.init();
  }

  initSize() {
    this.updateSize();
    window.addEventListener("resize", this.updateSize.bind(this), false);
  }

  updateSize() {
    const windowW = window.innerWidth;
    const windowH = window.innerHeight;
    let width = (this.canvas.width =
      windowW < 320 ? 320 : windowW > 480 ? 480 : windowW);
    let height = (this.canvas.height = windowH);

    this.size = {
      width,
      height,
      windowWidth: windowW,
    };
  }

  init() {
    this.startAnimationFrame();
  }

  startAnimationFrame() {
    if (this.animationId) cancelAnimationFrame(this.animationId);
    this.animationId = requestAnimationFrame(this.animationFrame.bind(this));
  }

  animationFrame() {
    this.ctx.clearRect(0, 0, this.size.width, this.size.height);
    this.drawDots();
    this.animationId = requestAnimationFrame(this.animationFrame.bind(this));
  }

  drawDots() {
    const { ctx, dots, size } = this;
    dots.value.forEach((ite, idx) => {
      ctx.beginPath();
      ctx.arc(ite.x, ite.y, ite.r, 0, 2 * Math.PI, true);
      ctx.fillStyle = ite.color;
      ctx.fill();
      dots.updateDot(ite, size);
    });
  }
}

let bgCanvas;
let mess = ref("");

onMounted(() => {
  bgCanvas = new CanvasAnimation(bg.value);
});

store
  .dispatch("user/login")
  .then((res) => {
    isLoading.value = false;
  })
  .catch((err) => {
    isLoading.value = false;
  });
</script>

<style>
.page {
  width: 100vw;
  height: 100vh;
  overflow:hidden;
  /* background-color: #000; */
}

.main {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  height: 100%;
  /* background: radial-gradient(
    circle at 50% 75%,
    #322e22 0%,
    #302310 50%,
    #010101 70%
  ); */
}

#bg {
  position: absolute;
  z-index: 0;
}
</style>
