import { userApi } from '@/http'
import Cookies from "js-cookie";
import tw from '@/assets/js/tw'
import { test } from "@/config/global";

export default {
    namespaced: true,
    state: {
        chat_id: 0,
        username: '',
        logo: '',
        point: 0,
        energy: 0,
        level: 1,
        dot_level: 1,
        energy_level: 1,
        friend_num: 0,
        friend_point: 0,
        // 0不能翻倍，1可以
        is_doubling: 0,
        ismarket: 0
    },
    mutations: {
        initUserGameData(state, userGameInfo) {
            state.username = userGameInfo.username
            state.logo = userGameInfo.logo
            state.point = userGameInfo.point
            state.energy = userGameInfo.energy
            state.level = userGameInfo.level
            state.dot_level = userGameInfo.dot_level
            state.energy_level = userGameInfo.energy_level
            state.friend_num = userGameInfo.friend_num
            state.friend_point = userGameInfo.friend_point
            state.is_doubling = userGameInfo.is_doubling
        },

        initUserInfo(state, userInfo) {
            state.chat_id = userInfo.chat_id
            state.logo = userInfo.logo
        },

        updatePoint(state, data) {
            state.point = data.point + data.curPoint
            state.energy = data.energy
        },

        upDot_level(state, point) {
            state.dot_level += 1
            state.point -= point
        },

        upEnergy_level(state, point) {
            state.energy_level += 1
            state.point -= point
            state.energy += 1
        },

        change_point(state, point) {
            state.point = point
        },

        change_is_doubling(state) {
            state.is_doubling = 0
        },
        change_market(state,data){
            state.ismarket = data.market
        }
    },
    actions: {
        login(context, data) {
            tw.init();
            let initData = tw.WebApp.initData;
            initData = initData ? initData : "1";
            data={
                test,
                data:initData
            }

            return new Promise((resolve, reject) => {
                userApi.login(data)
                    .then((res) => {
                        Cookies.set('token', res.result.token)
                        context.commit('initUserGameData', res.result.data)
                        context.commit('change_market',{market: res.market})
                        resolve()
                        // return userApi.getUserInfo()
                    })
                    // .then((res) => {
                    //     context.commit('initUserInfo', res.result)
                    //     resolve()
                    // })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        updatePoint(context, data) {
            context.commit('updatePoint', data)
            delete data.curPoint
            return new Promise((resolve, reject) => {
                userApi.updatePoint(data)
                    .then((res) => {
                        resolve(data)
                    }).catch((err) => {
                        reject(err)
                    })
            })
        },

        upDot(context, point) {
            return new Promise((resolve, reject) => {
                userApi.upDot()
                    .then((res) => {
                        context.commit('upDot_level', point)
                        resolve()
                    }).catch((err) => {
                        reject(err)
                    })
            })
        },

        upEnergy(context, point) {
            return new Promise((resolve, reject) => {
                userApi.upEnergy()
                    .then((res) => {
                        context.commit('upEnergy_level', point)
                        resolve()
                    }).catch((err) => {
                        reject(err)
                    })
            })
        },

        doublePoint(context) {
            return new Promise((resolve, reject) => {
                userApi.doublePoint()
                    .then((res) => {
                        context.commit('change_point', res.result.point)
                        context.commit('change_is_doubling')
                        resolve()
                    }).catch((err) => {
                        reject(err)
                    })
            })
        }
    },
    getters: {}
}