import axios from 'axios';
import config from './config';
import qs from 'qs';
import Cookies from "js-cookie";

// 使用vuex做全局loading时使用
// import store from '@/store'

export default function $axios(options) {
    return new Promise((resolve, reject) => {
        const instance = axios.create(config)

        // request 拦截器
        instance.interceptors.request.use(
            config => {
                // 带上token
                let token = Cookies.get('token')
                if (token) config.headers.Authorization = 'Bearer ' + token;
                config.data = config.data ? config.data : {}
                if (config.method === 'get') config.url += '?' + qs.stringify(config.data)
                return config
            },

            error => {
                if (axios.isAxiosError(error)) {
                    // 处理 AxiosError
                    if (error.response) {
                        // 请求成功但状态码不在 2xx 范围
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // 请求发出但没有收到响应
                        console.log(error.request);
                    } else {
                        // 在设置请求时发生了错误
                        console.log('Error', error.message);
                    }
                } else {
                    // 处理其他类型的错误
                    console.log('Error', error);
                }

                // 请求错误时
                // 1. 判断请求超时
                if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
                    console.log('timeout请求超时')
                    // return service.request(originalRequest);// 再重复请求一次
                    // store.commit('user/changeState', '请求超时失败')
                }
                // 2. 需要重定向到错误页面
                const errorInfo = error.response
                if (errorInfo) {
                    error = errorInfo.data  // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
                    const errorStatus = errorInfo.status; // 404 403 500 ...
                    // router.push({
                    //     path: `/error/${errorStatus}`
                    // })
                }
                return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
            }
        )

        // response 拦截器
        instance.interceptors.response.use(
            response => {
                let data = response.data
                // 根据返回的code值来做不同的处理
                return data.code === 200 ? data : Promise.reject(data)
            },
            err => {
                if (err && err.response) {
                    switch (err.response.status) {
                        case 400:
                            err.message = '请求错误'
                            break
                        case 401:
                            err.message = '未授权，请登录'
                            break
                        case 403:
                            err.message = '拒绝访问'
                            break
                        case 404:
                            err.message = `请求地址出错: ${err.response.config.url}`
                            break
                        case 408:
                            err.message = '请求超时'
                            break
                        case 500:
                            err.message = '服务器内部错误'
                            break
                        case 501:
                            err.message = '服务未实现'
                            break
                        case 502:
                            err.message = '网关错误'
                            break
                        case 503:
                            err.message = '服务不可用'
                            break
                        case 504:
                            err.message = '网关超时'
                            break
                        case 505:
                            err.message = 'HTTP版本不受支持'
                            break
                        default:
                            break
                    }
                }
                // console.error(err)
                return Promise.reject(err) // 返回接口返回的错误信息
            }
        )

        // 请求处理
        instance(options).then(res => {
            resolve(res)
            return false
        }).catch(error => {
            reject(error)
        })
    })
}